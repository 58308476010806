var render = function render(){var _vm=this,_c=_vm._self._c;return _c('gl-filtered-search-token',_vm._g(_vm._b({attrs:{"config":_vm.validatedConfig,"value":_vm.value,"active":_vm.active,"multi-select-values":_vm.selectedTokens},on:{"input":_vm.handleInput,"select":_vm.handleTokenValueSelected},scopedSlots:_vm._u([{key:"view-token",fn:function(viewTokenProps){return [_vm._t("view-token",null,{"viewTokenProps":/* eslint-disable @gitlab/vue-no-new-non-primitive-in-template */ {
        ...viewTokenProps,
        activeTokenValue: _vm.activeTokenValue,
        selectedTokens: _vm.selectedTokens,
      } /* eslint-enable @gitlab/vue-no-new-non-primitive-in-template */})]}},{key:"view",fn:function(viewTokenProps){return [_vm._t("view",null,{"viewTokenProps":/* eslint-disable @gitlab/vue-no-new-non-primitive-in-template */ {
        ...viewTokenProps,
        activeTokenValue: _vm.activeTokenValue,
        selectedTokens: _vm.selectedTokens,
      } /* eslint-enable @gitlab/vue-no-new-non-primitive-in-template */})]}},(_vm.suggestionsEnabled)?{key:"suggestions",fn:function(){return [(_vm.showDefaultSuggestions)?[_vm._l((_vm.availableDefaultSuggestions),function(token){return _c('gl-filtered-search-suggestion',{key:token.value,attrs:{"value":token.value}},[_vm._v("\n        "+_vm._s(token.text)+"\n      ")])}),_vm._v(" "),_c('gl-dropdown-divider')]:_vm._e(),_vm._v(" "),(_vm.showRecentSuggestions)?[_c('gl-dropdown-section-header',[_vm._v(_vm._s(_vm.__('Recently used')))]),_vm._v(" "),_vm._t("suggestions-list",null,{"suggestions":_vm.recentSuggestions,"selections":_vm.selectedTokens}),_vm._v(" "),_c('gl-dropdown-divider')]:_vm._e(),_vm._v(" "),(_vm.showPreloadedSuggestions)?_vm._t("suggestions-list",null,{"suggestions":_vm.preloadedSuggestions,"selections":_vm.selectedTokens}):_vm._e(),_vm._v(" "),(_vm.suggestionsLoading)?_c('gl-loading-icon',{attrs:{"size":"sm"}}):(_vm.showAvailableSuggestions)?[_vm._t("suggestions-list",null,{"suggestions":_vm.availableSuggestions,"selections":_vm.selectedTokens})]:(_vm.showNoMatchesText)?_c('gl-dropdown-text',[_vm._v("\n      "+_vm._s(_vm.__('No matches found'))+"\n    ")]):(_vm.hasFetched)?_c('gl-dropdown-text',[_vm._v(_vm._s(_vm.__('No suggestions found')))]):_vm._e(),_vm._v(" "),_vm._t("footer")]},proxy:true}:null],null,true)},'gl-filtered-search-token',_vm.$attrs,false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }